//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1ihzys2K1qLYuS_t-KSqwQh4FXiDBjww",
  authDomain: "quakly-738f3.firebaseapp.com",
  projectId: "quakly-738f3",
  storageBucket: "quakly-738f3.appspot.com",
  messagingSenderId: "836047681242",
  appId: "1:836047681242:web:a29bd9cd86a207ad8a57e3",
  measurementId: "G-XLPF7DMZFR",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
